import { css } from 'styled-components';
import BulkActions from 'granite-admin/core/components/Table/BulkActions';

const baseSpacing = 20;
const theme = {
  anchor: {
    color: 'secondary',
    fontWeight: 400,
  },
  rightbtnStyle: {
    borderRadius: '4px',
    fontWeight: '600',
  },
  button: {
    primary: {
      extend: ({ colorValue }) => {
        switch (colorValue) {
          case 'text':
            return {
              color: '#222D39',
              border: '1px solid #222D39',
              borderRadius: '4px',
              background: 'white',
            };
          case 'white':
            return {
              color: '#fbab31',
              borderRadius: '4px',
              borderColor: '#fbab31',
            };
          case 'secondary':
            return { borderRadius: '18px' };
          case 'cancel':
            return {
              color: '#f05050',
              border: '1px solid #f05050',
              borderRadius: '4px',
              background: 'white',
            };
          default:
            return {
              color: 'white',
              borderRadius: '4px',
            };
        }
      },
    },
    extend: {
      fontWeight: 500,
      fontSize: 12,
      margin: { bottom: '2.25rem' },
    },
    padding: {
      vertical: '6px',
      horizontal: '1.5rem',
    },
  },
  topNavBar: {
    topRightItemStyle: {
      height: '40px',
      fontSize: '14px',
      borderRadius: '4px',
      boxShadow: 'rgb(214 214 214) 3px 3px 6px',
    },
  },
  formField: {
    label: {
      size: '12px',
      margin: { horizontal: 'none', vertical: 'small' },
      color: 'dark-1',
      weight: 400,
      requiredIndicator: true,
    },
    error: {
      margin: 'none',
      weight: 400,
      size: '12px',
      color: { dark: 'white', light: 'status-error' },
    },
    extend: ({ noBorder, displayName }) =>
      css`
        ${noBorder &&
        `> label  div {
          border: none;
          > div {
            padding: 0;
          }
        }
        > div {
          border: none;
          > div {
            padding: 0;
          }
        }`}
        ${displayName === 'CheckBox' &&
        `
        > div  {
          height: 39.1px;
          justify-content: flex-end;
        }
        `}
      `,
  },
  icon: {
    size: {
      small: '14px',
      medium: '28px',
      large: '56px',
      xlarge: '112px',
    },
  },
  global: {
    searchBarBorderRadius: '4px',
    anchor: {
      color: { light: 'brand' },
      fontWeight: 400,
    },
    animation: {
      duration: '0.2s',
    },
    borderSize: {
      xsmall: '0.5px',
      small: '2px',
      medium: '4px',
      large: '6px',
      xlarge: '12px',
    },
    drop: {
      shadowSize: 'large',
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '0.125rem',
      xsmall: '0.25rem',
      small: '0.5rem',
      medium: '1rem',
      large: '2rem',
      largePercentage: '2.5%',
      xlarge: '4rem',
      xlargePercentage: '4%',
      xxlarge: '8rem',
      xxlargePercentage: '8%',
    },
    size: {
      xxsmall: '32px',
      xsmall: '64px',
      small: '128px',
      'medium-s': '192px',
      medium: '256px',
      'medium-l': '384px',
      large: '512px',
      xlarge: '768px',
      full: '100%',
    },
    breakpoints: {
      small: {
        value: 768,
        edgeSize: {
          xxsmall: '2px',
          xsmall: '3px',
          small: '6px',
          medium: '12px',
          large: '24px',
          largePercentage: '1.5%',
          xlarge: '48px',
          xlargePercentage: '2%',
          xxlarge: '70px',
          xxlargePercentage: '4%',
        },
        size: {
          xxsmall: '8px',
          xsmall: '12px',
          small: '16px',
          medium: '32px',
          large: '64px',
          xlarge: '128px',
        },
      },
    },
    hover: {
      background: {
        color: 'hover',
      },
    },
    colors: {
      'accent-1': '#85BF42',
      dropButtonHover: 'rgba(253,213,152,0.6)',
      //   brand: '#FBAB31',
      brand: '#104C79',
      'brand-dark': '#7D5518',
      'brand-light': '#FDD598',
      border: '#C6C6C6',
      'comp-1': '#2D6E16',
      'comp-2': '#6E162D',
      'dark-1': '#222D39',
      'dark-2': '#707070',
      'dark-3': '#646464',
      hover: 'rgba(253,213,152,0.6)',
      warningBG: '#ff324d33',
      icon: '#646464',
      placeholder: '#AAAAAA',
      widgetBackground: '#fff',
      labelColor: '#868686',
      bgColor: '#ffffff',
      secondary: '#EB8D2F',
      'secondary-light': '#95D7EE',
      'secondary-dark': '#15586F',
      'status-error': '#CC0000',
      'status-ok': '#24A000',
      'status-disabled': '#606060',
      'status-warning': '#D5CE00',
      info: '#08007E',
      darkText: '#222D39',
      text: { dark: 'white', light: '#222D39' },
      textColor: '#222D39',
      'sidebar-background': '#fff',
      'sidebar-drops': 'white',
      'sidebar-group': '#606060',
      'sidebar-icon': '#FBAB31',
      'sidebar-hover': '#012a46',
      'sidebar-divider': '#FDD598',
      'sidebar-svg-active': '#104C79',
      'sidebar-svg-hover': '#104C79',
      'sidebar-svg': '#104C79',
      'sidebar-label-active': '#222D39',
      'sidebar-label': '#222D39',
      'sidebar-label-hover': '#222D39',
      'sidebar-label-active-background': 'rgba(253,213,152,0.6)',
      'action-buttons-background': '#FBAB31',
      'full-screen-background-color': 'light-2',
      'notification-avatar-background': '#facfa7',
      'notification-avatar-color': '#104C79',
      iconBrand: '#023a60',
      'dark-orange': '#e18940',
      whiteColor: '#ffffff',
      blackColor: '#000',
      lightBlue: '#3E98C7',
      greenColor: 'green',
      forestGreenColor: '#449d44',
      redColor: '#ff0000',
      orangeTextIconColor: '#f3943c',
      blueColor: '#0000ff',
      grayColor: '#808080',
      pinkColor: '#e91e63',
      iceGrayColor: '#edf1f2',
      darkGreenColor: '#118911',
      lightGreyColor: '#ddd',
      softRedColor: '#f05050',
      borderGreyColor: '#dee5e7',
      borderBottomColor: ' #d8e1e3',
      pastelPurpleColor: '#7266ba',
      shamrockColor: '#27c24c',
      pictonBlueColor: '#23b7e5',
      burntOrangeColor: '#e18940',
      coralRedColor: '#f05050',
      navyBlueColor: '#023a60',
      moderateBlueColor: '#337ab7',
      lightOrangeColor: '#f0ad4e',
      caribbeanGreenColor: '#00d96f',
      deepOrangeColor: '#ff3d00',
      lightMintColor: '#96eeb5',
      steelBlue: '#6C89A8',
    },
    focus: {
      border: {
        color: 'brand',
      },
    },
    font: {
      family: "sans-serif, 'Open Sans'",
      size: '16px',
      // height: '20px',
    },
    input: {
      font: {
        size: 'medium',
      },
      padding: `${baseSpacing / 2}px`,
      weight: 400,
    },
  },
  heading: {
    responsiveBreakpoint: 'small',
    extend: {
      marginTop: 0,
    },
    font: {
      weight: 600,
      family: "sans-serif, 'Muli';",
    },
    level: {
      1: {
        font: {
          weight: 900,
        },
        // default size to heading level 1
        small: {
          size: '34px',
          height: '40px',
        },
        medium: {
          size: '50px',
          height: '46px',
        },
        large: {
          size: '82px',
          height: '88px',
        },
      },
      2: {
        small: {
          size: '26px',
          height: '32px',
        },
        medium: {
          size: '34px',
          height: '40px',
        },
        large: {
          size: '50px',
          height: '56px',
        },
      },
      3: {
        small: {
          size: '22px',
          height: '28px',
        },
        medium: {
          size: '26px',
          height: '32px',
        },
        large: {
          size: '34px',
          height: '40px',
        },
      },
      4: {
        small: {
          size: '16px',
          height: '22px',
        },
        medium: {
          size: '18px',
          height: '24px',
        },
        large: {
          size: '20px',
          height: '26px',
        },
      },
      5: {
        font: {
          weight: 400,
        },
        small: {
          size: '14px',
          height: '20px',
        },
        medium: {
          size: '16px',
          height: '22px',
        },
        large: {
          size: '18px',
          height: '24px',
        },
      },
    },
  },
  paragraph: {
    // default size to paragraph
    small: {
      size: '14px',
      height: '20px',
      maxWidth: 'none',
    },
    medium: {
      size: '18px',
      height: '24px',
      maxWidth: 'none',
    },
    large: {
      size: '22px',
      height: '28px',
      maxWidth: 'none',
    },
    extend: {
      fontWeight: 300,
    },
  },
  radioButton: {
    container: {
      extend: css`
        font-size: 12px;
      `,
    },
    size: '14px',
  },
  text: {
    alert: {
      size: '10px',
      maxWidth: 'none',
    },
    xsmall: {
      size: '8px',
      height: '11px',
      maxWidth: 'none',
    },
    small: {
      size: '10px',
      height: '13px',
      maxWidth: 'none',
    },
    medium: {
      size: '12px',
      height: '16px',
      maxWidth: 'none',
    },
    large: {
      size: '14px',
      height: '18px',
      maxWidth: 'none',
    },
    xlarge: {
      size: '16px',
      height: '20px',
    },
    heroDesc: {
      size: '1.875rem',
      height: 'normal',
      maxWidth: 'none',
    },
  },
  textInput: {
    extend: {
      background: 'white',
      fontSize: '12px',
      height: '38px',
    },
    disabled: {
      opacity: 0.5,
    },
  },
  maskedInput: {
    extend: {
      background: 'white',
      fontSize: '12px',
    },
  },
  calendar: {
    extend: {
      color: '#000',
    },
    small: {
      daySize: '18px',
    },
    medium: {
      daySize: '36px',
    },
    large: {
      daySize: '72px',
    },
  },
  checkBox: {
    gap: 'medium',
    border: {
      // color: {
      //   dark: 'rgba(255, 255, 255, 0.5)',
      //   light: 'text',
      // },
      width: '1px',
    },
    icon: {
      extend: ({ checked }) => {
        if (checked) return { stroke: 'white' };
      },
    },
    check: {
      extend: ({ checked }) => {
        if (checked)
          return {
            borderColor: '#0099e5',
            backgroundColor: '#0099e5',
            radius: '2px',
          };
      },
    },
    size: '13px',
    toggle: {
      knob: {
        extend: {
          top: '1px',
          left: '2px',
          width: '11px',
          height: '11px',
        },
      },
      size: '35px',
      color: { light: '#606060' },
      extend: ({ checked }) => {
        if (checked)
          return {
            borderColor: '#fbab31',
            color: { light: '#606060' },
          };
      },
    },
  },
  tab: {
    margin: undefined,
    color: 'text',
    border: {
      color: 'transparent',
      active: { color: 'secondary' },
    },
    active: {
      color: 'secondary',
    },
    pad: {
      top: 'small',
      horizontal: 'xlarge',
    },
    extend: {
      'padding-bottom': '0.5rem',
      'border-right': '1px solid rgba(198,198,198,0.2)',
    },
  },
  tabs: {
    background: 'white',
    header: {
      extend: {
        justifyContent: 'flex-start',
      },
    },
  },
  table: {
    extend: {
      th: {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingRight: '2.5rem',
        paddingLeft: '1rem',
      },
      td: {
        wordBreak: 'break-word',
      },
    },
    bulkActionsComponent: BulkActions,
  },
  accordion: {
    heading: {
      level: '5',
      margin: {
        vertical: 'small',
      },
    },
  },
  filterBackground: '#eeeef069',
  graniteTable: {
    dataTableConfigs: {
      autoSavePreference: false,
    },
    table: {},
    tableBorder: true,
    defaultProps: {
      components: {
        bulkActions: BulkActions,
      },
      actionsConfig: {
        visibleHover: row => (row?.original?.pk ? true : false),
      },
      utilsConfig: {},
      selectionConfig: {},
      rowColor: '',
    },
    hoverActionItems: false,
    actionColumnHidable: false,
    filtersDropButtonProps: {
      iconColor: 'black',
    },
    header: {
      background: '#fff',
      color: 'black',
      fontSize: 'text.medium',
      fontWeight: 'bold',
      borderBottom: '1px solid black',
      sortAscActiveColor: '#000',
      sortAscInactiveColor: '#fff',
      sortDescActiveColor: '#000',
      sortDescInactiveColor: '#fff',
      sortAscColor: '#0000006e',
      sortDescColor: '#0000006e',
      // borderBottomBackground: 'border',

      // 'border-bottom': '1px solid rgba(198,198,198,0.2)',
    },
    row: {
      background: 'white',
      color: 'text',
      font: 'text.medium',
      hoverBackground: 'hover',
      fontSize: 'text.medium',
      evenRowBackground: 'white',
    },
    th: {
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingRight: '2.5rem',
      paddingLeft: '1rem',
    },
    extend: css``,
  },
  DNA_SLIDER_WIDTH: '480px', // Documents/notes/audits slider width
  toast: {
    position: 'top',
    margin: { top: '20px', horizontal: 'xxlarge' },
    colors: {
      success: 'status-ok',
      warning: 'status-warning',
      error: 'status-error',
      info: 'brand',
    },
  },
  tip: {
    content: {
      background: 'dark-3',
      elevation: 'small',
      margin: 'xsmall',
      pad: { vertical: 'xsmall', horizontal: 'small' },
      round: 'xsmall',
      style: { fontSize: '12px' },
    },
    drop: { align: { top: 'bottom' }, background: 'none', elevation: 'none', margin: 'none' },
  },
  listOpenNewTab: true,
};

export default theme;
