import { env } from 'granite-admin/env';

const BASE_URL = env.REACT_APP_API_BASE_URL;

export const ROLLCALL_API_URL = BASE_URL + env.REACT_APP_ROLLCALL_API;

export const ORGANISATION_API_URL = BASE_URL + env.REACT_APP_ORGANISATION_API;

export const PLUGIN_API_URL = env.REACT_APP_API_BASE_URL + env.REACT_APP_PLUGINS_API;

export const PLUGINS_API_URL = BASE_URL + env.REACT_APP_PLUGINS_API;
